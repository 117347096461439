import isArray from 'lodash/isArray';
import {
  RESET_FORM,
  CHANGE_STEP,
  SET_ACTIONS,
  SET_FORM,
  UPDATE_ATTRIBUTE,
  SET_STEP,
  FOCUS_ATTRIBUTE,
  UPDATE_ERROR,
  SET_DATA,
  SET_ERROR,
  REMOVE_ERROR,
  RESET_DATA,
  SET_EMAIL_VERIFIED,
  SET_FORM_PAGE
} from './constants';

export const initialState = {
  accessToken: '',
  actions: {
    onComplete: false,
    onSave: false,
    onBack: false,
  },
  editField: {
    name: '',
    value: '',
  },
  errors: {},
  form: {},
  formData: {},
  missingFields: [],
  loaded: false,
  page: '',
  focus: '',
  step: 0,
  emailVerified: false,
  recaptchaToken: '',
};

const dynamicForm = (state = { ...initialState }, action) => {
  const { name, value, actions, data } = action;
  let errors = state.errors;

  switch (action.type) {
    case RESET_FORM: {
      return {
        ...state,
        formData: {},
      };
    }
    case FOCUS_ATTRIBUTE: {
      return {
        ...state,
        focus: name,
      };
    }
    case SET_ACTIONS: {
      return {
        ...state,
        actions,
      };
    }
    case SET_FORM_PAGE: {
      return {
        ...state,
        page: name,
      };
    }
    case SET_FORM: {
      const user = data.user || {};
      const formData = { ...user, ...user.primaryAddress, ...user.phone };
      return {
        ...state,
        form: {
          ...state.form,
          [name]: { ...initialState.form[name], ...data.form },
        },
        page: name,
        formData: data.reverse
          ? { ...formData, ...state.formData }
          : { ...state.formData, ...formData },
        errors: data.errors || {},
        step: data.step || 0,
        loaded: true,
      };
    }
    case RESET_DATA:
      return {
        ...initialState,
        form: { ...state.form },
      };
    case SET_DATA: {
      return {
        ...state,
        formData: { ...state.formData, ...value },
      };
    }
    case SET_EMAIL_VERIFIED: {
      return {
        ...state,
        emailVerified: { ...value },
      };
    }
    case CHANGE_STEP: {
      return {
        ...state,
        step: state.step + action.value,
      };
    }
    case SET_ERROR: {
      return {
        ...state,
        errors: value,
      };
    }
    case SET_STEP: {
      const step = action.value;
      return {
        ...state,
        step,
      };
    }
    case REMOVE_ERROR: {
      delete errors[name];
      return {
        ...state,
        errors: { ...errors },
      };
    }
    case UPDATE_ERROR: {
      errors = {
        ...state.errors,
        [name]: value,
      };
      return {
        ...state,
        errors,
      };
    }
    case UPDATE_ATTRIBUTE:
      if (value === null) {
        return {
          ...state,
          formData: {
            ...state.formData,
            [name]: null,
          },
        };
      }
      if (!isArray(value) && typeof value === 'object') {
        return {
          ...state,
          formData: {
            ...state.formData,
            [name]: {
              ...value,
            },
          },
        };
      }
      return {
        ...state,
        formData: {
          ...state.formData,
          [name]: value,
        },
      };
    default: {
      return state;
    }
  }
};

export default dynamicForm;
