import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Text } from '@shell-b2c/web-design-system';

export default function Link({
  children,
  to,
  testId = 'link-testId',
  textColor = 'textPrimary',
  ...props
}) {
  if (to) {
    return (
      <RouterLink to={to} {...props} data-testId={testId}>
        <Text variant="link" color={textColor}>
          {children}
        </Text>
      </RouterLink>
    );
  }

  return (
    <a {...props}>
      <Text variant="link" color={textColor}>
        {children}
      </Text>
    </a>
  );
}
