import {
  SET_LOGIN_FORM,
  SET_LOGIN_ATTRIBUTE,
  RESET_LOGIN_FORM,
  countriesThatMigrateFromNoLoyaltyToLoyalty,
} from './constants';
import { LOGIN_HEADER, MPP_FORGOT_PASSWORD } from './headers';
import { VALIDATION_ERROR } from '../dynamic-form/constants';
import {
  LOGIN_PAGE,
  SOL_LOGIN_PAGE,
  LOYALTY_MIGRATION_PAGE,
  SOL_MIGRATION_PAGE,
} from '../../shared/formPages';
import {
  PROFILE_PATHNAME,
  FORGOT_PASSWORD_PATHNAME,
  LOGIN_PATHNAME,
  SECURITY_UPDATE_OVERVIEW_PATHNAME,
  VERIFICATION_CODE_PATHNAME,
  SOL_ATTACH_WELCOME_PATHNAME,
  LOYALTY_FUTURES_ATTACH_WELCOME_PATHNAME,
  SAP_HYBRIS_ATTACH_WELCOME_PATHNAME,
  MFA_OTP_PATHNAME,
  SCOPES_AUTHORIZATION_SCREEN,
  TWOFA_PROMPT_PATHNAME,
  PROGRESSIVE_PROFILING_ONBOARDING,
  PROGRESSIVE_PROFILING_LOYALTY_REQUIRED,
  TERMS_CONDITIONS_GO_PLUS,
  PROMPT_CHECK_POPUP_ABOUT_COMMS,
} from '../../shared/pathnames';
import {
  SDA_LOGIN_APP_PATHNAME,
  LOGIN_READY_APP_PATHNAME,
  OPEN_HELP_MFA_APP,
} from '../../shared/appPathnames';
import {
  checkPartnerOnExternalSystemIds,
  getCaptchaToken,
} from '../../shared/utils';
import {
  makeSelectQuery,
  makeSelectQueryMarket,
  makeSelectIsGoPlusCVP,
  makeSelectQueryLock,
  makeSelectIsMotoristApp,
  makeSelectIsPartnerCVP,
  makeSelectIsMotoristFirebladeCVP,
  makeSelectIsLoyaltyFuturesCVP,
  makeSelectIsSAPHybrisCVP,
  makeSelectIsPartner,
  makeSelectQueryScopes,
  makeSelectRegistrationActivationStatus,
} from '../app/selectors';
import { makeSelectIsPhoneNumberFormActive } from '../auth/selectors';
import {
  getSignInForm,
  getForgotPinFUAGForm,
  getLoyaltyAddressForm,
  getSignInMobileForm,
} from '@shell-b2c/http-frontend/dist/src/components/core/forms';
import { login } from '@shell-b2c/http-frontend/dist/src/components/core/auth';
import { checkPromptProfile } from '@shell-b2c/http-frontend/dist/src/components/core/twofa/index';
import {
  validateForm,
  setFormStep,
  setFormData,
  resetForm,
  setForm,
  setFormError,
} from '../dynamic-form/actions';
import {
  makeSelectFormPageName,
  makeSelectFormData,
} from '../dynamic-form/selectors';
import { UK_MARKET, getMarketCountry } from '../../shared/markets';
import {
  checkEmailExist,
  resendEmailVerification,
} from '@shell-b2c/http-frontend/dist/src/components/core/settings';
import { WRONG_MARKET } from '../app/notifications';
import {
  makeSelectIsLoyaltyActivatedInAppSettings,
  makeSelectIsGoPlusActivatedInSettings,
  makeSelectDefaultAuthentication,
  makeSelectIsLoyaltyFuturesActivatedInAppSettings,
  makeSelectIsSAPHybrisActivatedInAppSettings,
  makeSelectSettingsPartnersByClientId,
  makeSelectEmailAuthentication,
  makeSelectPhoneAuthentication,
  makeSelectSettingsHelpURL,
} from '../settings/selectors';
import { solMigration } from '@shell-b2c/http-frontend/dist/src/components/core/loyalty';
import { RESET_MIGRATION } from '../sol/migration/email/constants';
import { sendKochavaEvent, setDataToStore } from '../app/utils';
import {
  redirectToCVP,
  goPlusRedirect,
  setQuery,
  saveQuery,
  sendAdobeAnalyticsEvent,
  setLocation,
  setSpinner,
  saveCredentials,
} from '../app/actions';
import { LOYALTY_SERVICE } from '../../shared/formServices';
import {
  showAddress,
  isSOLRegistrationValid,
  showLoyaltyCardBlockedNotification,
} from '../sol/shared/actions';
import {
  showNotification,
  showDefaultErrorNotification,
  setNotificationVisible,
} from '../notification/actions';
import LockAnimation from '../../shared/components/LockAnimation';
import { loadUserProfile, userToVerifyEmail } from '../profile/actions';
import { showPopup, closePopup } from '../popup/actions';
import browserHistory from '../../router/history';
import {
  getLoyaltyAttachDataFromSOLData,
  createUserProfileFromSOLData,
} from '../../shared/mapping';
import { browserTabNameLogged } from '../../shared/getBrowserTabName';
import { HTTP_STATUS } from '@shell-b2c/http-frontend/dist/src/components/HttpError';
import adobeAnalyticTags from '../../shared/adobeAnalyticTags';
import { selectLoginDomain } from './selectors';
import { setAuthType } from '../auth/actions';
import {
  CARD_ALREADY_MIGRATED_TO_SSO,
  CARD_BLOCKED,
  CARD_DISABLED,
  CARD_NOT_FOUND,
  DISALLOWED_CARD_TYPE,
  INVALID_CARD_ID_LENGTH,
} from '../../shared/solResponseErrorCodes';
import { lodAttach } from '@shell-b2c/http-frontend/dist/src/components/core/lod';
import {
  showNotSupportedNotification,
  showCardMigratedPopup,
} from '../sol/attach/actions';
import { buildNotification } from '../notification/utils';
import PaperAirplaneAnimation from '../../shared/components/PaperAirplaneAnimation';
import { buildPopup } from '../popup/utils';
import { makeSelectIsNotificationVisible } from '../notification/selectors';
import { saveProfile } from '@shell-b2c/http-frontend/dist/src/components/core/user';
import lokaliseTags from '../../shared/lokaliseTags';
import { setIsSMS, setOtpMobile, setOtpToken } from '../mfa/actions';
import { setAcceptedScopes, setScopesErrors } from '../scopes/auth/actions';
import { logout } from '../logout/actions';
import { generateHash } from '../../http/core/utils';
import { cloneDeep } from 'lodash';
import kochavaTags from '../../shared/kochavaTags';

export const VERIFY_ACCOUNT_SUCCESS = 'verify_account_success';

export function initializeLogin(setHeader, setOpen) {
  return (dispatch, getState) => {
    const isMotoristFireblade = makeSelectIsMotoristFirebladeCVP()(getState());
    const queryLock = makeSelectQueryLock()(getState());
    const header = queryLock ? MPP_FORGOT_PASSWORD : LOGIN_HEADER;

    if (queryLock || isMotoristFireblade) {
      header.menu.leftMenu.event = () => {
        dispatch(redirectToCVP());
      };
    } else {
      header.menu.leftMenu.event = () => {
        browserHistory.push('/');
      };
    }
    setHeader(header);
    dispatch(sendAdobeAnalyticsEvent(adobeAnalyticTags[LOGIN_PAGE].tag));
    const defaultAuthMethod = makeSelectDefaultAuthentication()(getState());
    dispatch(setAuthType(defaultAuthMethod === 'phone'));

    const registrationActivationStatus =
      makeSelectRegistrationActivationStatus()(getState());
    if (registrationActivationStatus === VERIFY_ACCOUNT_SUCCESS) {
      setOpen(true);
    }
  };
}

export function getLoginForm() {
  return (dispatch, getState) => {
    const isLock = !!makeSelectQuery()(getState()).lock;
    const loginDomain = selectLoginDomain()(getState());
    const isPhoneNumberActive = makeSelectIsPhoneNumberFormActive()(getState());
    const isLoyaltyActivated = makeSelectIsLoyaltyActivatedInAppSettings()(
      getState()
    );

    const numberActiveAndMobileForm =
      isPhoneNumberActive &&
      loginDomain.form.description === 'signInMobileForm';
    const numberNotActiveAndWebForm =
      !isPhoneNumberActive && loginDomain.form.description === 'signInForm';
    if (
      (numberActiveAndMobileForm || numberNotActiveAndWebForm) &&
      getState().login.lock === isLock
    ) {
      const loginForm = {
        name: LOGIN_PAGE,
        data: {
          form: getState().login.form,
          errors: {},
          user: getState().login.user,
        },
      };
      dispatch(setForm(loginForm));
      dispatch(setSpinner(false));
    } else {
      let getLoginFormRequest;
      if (isLock) {
        getLoginFormRequest = getForgotPinFUAGForm;
      } else if (isPhoneNumberActive) {
        getLoginFormRequest = getSignInMobileForm;
      } else {
        getLoginFormRequest = getSignInForm;
      }

      getLoginFormRequest()
        .then((data) => {
          Object.keys(data.properties || {}).forEach((p) => {
            if (
              data.properties[p].name === 'signInEmailAddress' &&
              isLoyaltyActivated
            ) {
              const validations = data.properties[p].validations;
              Object.keys(validations || {}).forEach((v) => {
                if (validations[v].rule !== 'required') {
                  delete validations[v];
                }
              });
            }
          });

          const form = data;
          const query = getState().app.query;
          const user = { ...getState().login.user };
          if (
            (query.email || query.email_encoded) &&
            !getState().dynamicForm.formData.signInEmailAddress
          ) {
            user.signInEmailAddress =
              query.email || (query.email_encoded && atob(query.email_encoded));
          }
          const loginForm = {
            name: LOGIN_PAGE,
            data: {
              form,
              errors: {},
              user,
            },
          };
          dispatch(setForm(loginForm));
          dispatch({
            type: SET_LOGIN_FORM,
            data,
            lock: isLock,
          });
          if (makeSelectIsMotoristApp()(getState())) {
            dispatch(setLocation(LOGIN_READY_APP_PATHNAME));
          }
        })
        .catch(() => {
          dispatch(showDefaultErrorNotification());
        });
    }
  };
}

export function loginWithSOL(data, completeLoyalty, setHeader) {
  return async (dispatch, getState) => {
    const isLoyaltyActivated = makeSelectIsLoyaltyActivatedInAppSettings()(
      getState()
    );
    if (isLoyaltyActivated) {
      try {
        const registrationStatus = await dispatch(
          isSOLRegistrationValid({
            [isNaN(data.username) ? 'emailAdress' : 'cardId']: data.username,
          })
        );
        if (registrationStatus.isValid) {
          const market = makeSelectQueryMarket()(getState());
          const formPage = makeSelectFormPageName()(getState());
          const solMigrationResponse = await solMigration(data);

          if (solMigrationResponse.response_status_text === 'OK') {
            if (formPage === LOGIN_PAGE || formPage === SOL_MIGRATION_PAGE) {
              const user = createUserProfileFromSOLData(
                solMigrationResponse,
                market
              );
              dispatch(setQuery({ user }));
              dispatch(setFormData({}));
              setDataToStore(
                'query',
                JSON.stringify(makeSelectQuery()(getState()))
              );
              dispatch({
                type: RESET_MIGRATION,
              });
              browserHistory.push(SECURITY_UPDATE_OVERVIEW_PATHNAME);
            } else {
              const migrationData = getLoyaltyAttachDataFromSOLData(
                solMigrationResponse,
                market
              );
              const { cardType } = makeSelectFormData()(getState());
              dispatch(setFormData({ ...migrationData, cardType }));
              const form = await getLoyaltyAddressForm();
              const solForm = { ...form };
              const dataForm = {
                form: solForm,
                errors: {},
                user: {},
              };
              const loyaltyMigrationForm = {
                name: LOYALTY_MIGRATION_PAGE,
                data: dataForm,
              };
              dispatch(setForm(loyaltyMigrationForm));
              const step =
                getState().dynamicForm.form.loyaltyMigration.order.mobile
                  .length;
              dispatch(setFormStep(step));
              dispatch(showAddress(migrationData, completeLoyalty, setHeader));
            }
          } else {
            dispatch(
              handleSolLoginErrors(solMigrationResponse.response_status, data)
            );
          }
        } else {
          dispatch(handleSolLoginErrors(registrationStatus.code, data));
        }
      } catch (error) {
        dispatch(
          showDefaultErrorNotification(
            null,
            lokaliseTags.SSO_ERROR_GENERIC_SOL_MIGRATION_FAILED_TEXT
          )
        );
      }
    }
  };
}

export function loginWithSSO(translate) {
  return async (dispatch, getState) => {
    const dynamicForm = getState().dynamicForm;
    const { udid } = makeSelectQuery()(getState());

    try {
      await dispatch(setFormError());
      await dispatch(validateForm(LOGIN_PAGE));
      dispatch(setSpinner(true));

      const { formData } = dynamicForm;
      const recaptchaToken = await getCaptchaToken('login');

      const loginRequest = {
        password: formData.currentPassword || formData.password,
        udid,
        recaptchaToken,
      };
      const isMobileLogin = formData.signInMobile || formData.mobile;

      if (isMobileLogin) {
        loginRequest.mobile =
          formData.signInMobile.replace(/ /g, '') ||
          formData.mobile.replace(/ /g, '');
      } else {
        loginRequest.email =
          formData.signInEmailAddress.toLowerCase() ||
          formData.email.toLowerCase();
      }

      const fakeID = generateHash(
        isMobileLogin ? loginRequest.mobile : loginRequest.email
      );
      dispatch(sendKochavaEvent(kochavaTags.loginTags.loginClick, { fakeID }));

      const loginResponse = await login(loginRequest, {
        withMobile: isMobileLogin,
      });

      const query = {
        accessToken: loginResponse.accessToken,
        refreshToken: loginResponse.refreshToken,
      };
      setDataToStore('auth', JSON.stringify(query), true);
      dispatch({
        type: RESET_LOGIN_FORM,
      });
      dispatch(saveQuery(query));
      dispatch(
        sendAdobeAnalyticsEvent(adobeAnalyticTags[LOGIN_PAGE].tag, 'signIn')
      );
      dispatch(
        saveCredentials({
          username: loginRequest.email || loginRequest.mobile,
          password: loginRequest.password,
          skipForm: true,
          noDeleteSpinner: true,
        })
      );
      dispatch(
        handleLoginSuccess({
          profile: loginResponse,
          url: SDA_LOGIN_APP_PATHNAME,
          translate,
        })
      );
      dispatch(resetForm());
    } catch (error) {
      dispatch(loginCatchErrors(error));
    }
  };
}

const loginCatchErrors = (HttpError) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_LOGIN_ATTRIBUTE,
      name: 'loginTry',
      value: getState().login.loginTry + 1,
    });

    const { type, errors } = HttpError;

    if (type === VALIDATION_ERROR) {
      return dispatch(setSpinner(false));
    }

    if (HttpError.wrongCaptcha()) {
      return dispatch(showDefaultErrorNotification())
    }

    if (HttpError.accountLocked()) {
      return dispatch(userBlocked());
    }

    const errorsData = HttpError.getData(0);
    if (
      errorsData &&
      errorsData.maxLoginAttempts - errorsData.loginAttempts === 1
    ) {
      dispatch(showLastAttempPopup());
      return dispatch(setSpinner(false));
    }

    if (HttpError.isLockedForever()) {
      dispatch(userBlockedForever());
      return dispatch(setSpinner(false));
    }

    if (HttpError.otpRequired()) {
      const { otp_token, phone } = errorsData;
      dispatch(setOtpToken(otp_token));
      dispatch(setOtpMobile(phone));
      const { source } = HttpError.getError(0);
      const isPhoneAuth = makeSelectPhoneAuthentication()(getState());
      if (source?.type === 'sms' || isPhoneAuth) {
        dispatch(setIsSMS(true));
      }
      browserHistory.push(MFA_OTP_PATHNAME);

      const _adobeAnalyticErrorTag = {
        ...adobeAnalyticTags[LOGIN_PAGE].tag,
        errorMessage: '406 - OTP required',
      };
      dispatch(sendAdobeAnalyticsEvent(_adobeAnalyticErrorTag, 'error'));
      return;
    }

    if (HttpError.wrongMarket()) {
      dispatch(showNotification(WRONG_MARKET));
      return dispatch(setSpinner(false));
    }

    if (HttpError.emailNotVerified()) {
      const email = errorsData?.emailAddress;
      if (email) {
        const analyticsObject =
          adobeAnalyticTags[LOGIN_PAGE].resendEmailConfirmation;
        analyticsObject.fakeId = generateHash(email);
        dispatch(sendAdobeAnalyticsEvent(analyticsObject));
        dispatch(userToVerifyEmail({ email }));
        dispatch(resendEmail(email));
      }
      return dispatch(setSpinner(false));
    }

    if (HttpError.mobileNotVerified()) {
      const mobile = errorsData.mobile;
      if (mobile) {
        browserHistory.push(VERIFICATION_CODE_PATHNAME, { mobile });
      }
      return;
    }

    if (HttpError.deviceNotAllowed()) {
      const _errorMessage = lokaliseTags.SSO_FORMS_ERRORS_DEVICE_CANNOT_LOGIN;
      dispatch(
        setFormError({
          currentPassword: _errorMessage,
        })
      );
      const _adobeAnalyticErrorTag = {
        ...adobeAnalyticTags[LOGIN_PAGE].tag,
        _errorMessage,
      };
      dispatch(sendAdobeAnalyticsEvent(_adobeAnalyticErrorTag, 'error'));
      return dispatch(setSpinner(false));
    }

    if (HttpError.serverError()) {
      dispatch(showDefaultErrorNotification());
      return dispatch(setSpinner(false));
    }

    if (HttpError.isPreconditionRequired('email_not_verified')) {
      dispatch(showReactivationNotification(errors?.[0]?.data?.emailAddress));
      return dispatch(setSpinner(false));
    }

    const errorMessage =
      HttpError.getData(0, 'fields.signInForm[0]') ||
      lokaliseTags.SSO_FORMS_ERRORS_USERNAME_PASSWORD_INVALID;
    dispatch(
      setFormError({
        currentPassword: errorMessage,
      })
    );
    const adobeAnalyticErrorTag = {
      ...adobeAnalyticTags[LOGIN_PAGE].tag,
      errorMessage: lokaliseTags.SSO_FORMS_ERRORS_USERNAME_PASSWORD_INVALID,
    };
    dispatch(sendAdobeAnalyticsEvent(adobeAnalyticErrorTag, 'error'));
    return dispatch(setSpinner(false));
  };
};

export function showReactivationNotification(email) {
  return (dispatch) => {
    const notification = buildNotification({
      animation: PaperAirplaneAnimation,
      title: lokaliseTags.SSO_VERIFY_EMAIL_LABEL,
      text: {
        key: lokaliseTags.SSO_VERIFY_ACCOUNT_MESSAGE_TEXT_TPDS,
        values: [email],
      },
      closeAction: () => dispatch(setNotificationVisible(false)),
      buttons: [
        {
          label: lokaliseTags.SSO_VERIFY_EMAIL_VERIFIED_BUTTON,
        },
      ],
    });

    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags.partialAccountReactivation.pageLoad
      )
    );

    dispatch(showNotification(notification));
  };
}

export function showLastAttempPopup() {
  return (dispatch) => {
    const adobeAnalyticForgotPasswordTag = {
      ...adobeAnalyticTags[LOGIN_PAGE].ctaForgotPassword,
    };

    dispatch(
      showPopup(
        buildPopup({
          title: lokaliseTags.SSO_LOGIN_LAST_ATTEMPT_TITLE,
          text: lokaliseTags.SSO_LOGIN_LAST_ATTEMPT_TEXT,
          buttons: [
            {
              label: lokaliseTags.SSO_LOGIN_TRY_AGAIN,
              outlined: true,
              event: () => {
                adobeAnalyticForgotPasswordTag.cta =
                  lokaliseTags.SSO_LOGIN_TRY_AGAIN;
                dispatch(
                  sendAdobeAnalyticsEvent(
                    adobeAnalyticForgotPasswordTag,
                    'tileClick'
                  )
                );
                dispatch(closePopup());
              },
            },
            {
              label: lokaliseTags.SSO_LOGIN_FORGOT_PASSWORD,
              event: () => {
                browserHistory.push(FORGOT_PASSWORD_PATHNAME);
                adobeAnalyticForgotPasswordTag.cta =
                  lokaliseTags.SSO_LOGIN_FORGOT_PASSWORD;
                dispatch(
                  sendAdobeAnalyticsEvent(
                    adobeAnalyticForgotPasswordTag,
                    'tileClick'
                  )
                );
              },
            },
          ],
        })
      )
    );
  };
}

function resendEmail(email) {
  return async (dispatch) => {
    try {
      await resendEmailVerification({ email });
    } catch (error) {
      if (error.status === HTTP_STATUS.SERVER_ERROR) {
        browserHistory.push(LOGIN_PATHNAME);
        dispatch(setNotificationVisible(false));
      } else {
        dispatch(
          showDefaultErrorNotification(
            null,
            lokaliseTags.SSO_ERROR_GENERIC_JANRAIN
          )
        );
      }
    }
  };
}

export const handlePromptCheckErrorsWithoutProgressiveProfiling = (
  httpError,
  profile
) => {
  return async (dispatch) => {
    if (httpError.popupNotEnabledPropmtCheck()) {
      await dispatch(loadUserProfile({ ...profile }));
      browserHistory.push(PROMPT_CHECK_POPUP_ABOUT_COMMS);
      return true;
    }

    if (httpError.twoFARequired()) {
      await dispatch(loadUserProfile({ ...profile }));
      browserHistory.push(TWOFA_PROMPT_PATHNAME);
      return true;
    }
    if (httpError.scopesError()) {
      dispatch(
        showDefaultErrorNotification(
          () => dispatch(redirectToCVP()),
          lokaliseTags.SSO_TECHNICAL_ERROR_SCOPES
        )
      );
      return true;
    }
    if (httpError.scopesRequired()) {
      const errorData = httpError.errors.map((err) => {
        const detail = err.detail?.split(':');
        return {
          ...err,
          action: detail[0],
          name: detail[1],
        };
      });
      const missingScopes = errorData.filter(
        (scope) => scope.title === 'missing_scope'
      );
      const acceptedScopes = errorData.filter(
        (scope) => scope.title === 'existing_scope'
      );

      const scopesErrors = {
        missingScopes,
        otpToken: httpError?.data.otp_token || null,
      };

      setDataToStore('scopesError', JSON.stringify(scopesErrors), true);
      setDataToStore('acceptedScopes', JSON.stringify(acceptedScopes), true);

      dispatch(setScopesErrors(scopesErrors));
      dispatch(setAcceptedScopes(acceptedScopes));

      browserHistory.push(SCOPES_AUTHORIZATION_SCREEN);
      return true;
    }
    return false;
  };
};

const handlePromptCheckErrors = (httpError, profile) => {
  return async (dispatch, getState) => {
    const promiseResponse = await dispatch(
      handlePromptCheckErrorsWithoutProgressiveProfiling(httpError, profile)
    );
    if (promiseResponse) return;

    if (httpError.missingProfileInfo()) {
      const isPartnerCVP = makeSelectIsPartner()(getState());
      if (!isPartnerCVP) {
        await dispatch(loadUserProfile({ ...profile }));
        return browserHistory.push(PROGRESSIVE_PROFILING_ONBOARDING);
      } else {
        return browserHistory.push(PROGRESSIVE_PROFILING_LOYALTY_REQUIRED);
      }
    }
    return dispatch(
      showDefaultErrorNotification(() => {
        dispatch(logout(true, false, { doRedirectToCVP: true }));
      })
    );
  };
};

const noLoyaltyMarket = (
  profile,
  isLoyaltyFuturesActivatedInSettings,
  isLoyaltyFuturesCVP
) => {
  return async (dispatch) => {
    const { market, loyalty } = profile;
    const { country } = market;
    if (
      countriesThatMigrateFromNoLoyaltyToLoyalty.includes(country) &&
      isLoyaltyFuturesActivatedInSettings &&
      isLoyaltyFuturesCVP
    ) {
      const { accounts, activatedGoplus, activatedAtGoplus } = loyalty;
      const goPlusIsNotActivated = !activatedGoplus && !activatedAtGoplus;
      if (accounts?.length === 0 && goPlusIsNotActivated) {
        await dispatch(loadUserProfile({ ...profile }));
        browserHistory.push(TERMS_CONDITIONS_GO_PLUS);
        return true;
      }
    }
    return false;
  };
};

export function handleLoginSuccess({
  profile,
  url,
  translate,
  loginFlow = true,
  mfaFlow = false,
  ignorePromptCheck = false,
}) {
  return async (dispatch, getState) => {
    try {
      if (!ignorePromptCheck) {
        const scopes = makeSelectQueryScopes()(getState()).join(' ');
        await checkPromptProfile(scopes);
      }
    } catch (error) {
      return dispatch(handlePromptCheckErrors(error, profile));
    }
    const isGoPlusActivatedInSettings = makeSelectIsGoPlusActivatedInSettings()(
      getState()
    );
    const isLoyaltyFuturesActivatedInSettings =
      makeSelectIsLoyaltyFuturesActivatedInAppSettings()(getState());
    const isLoyaltyActivatedInSettings =
      makeSelectIsLoyaltyActivatedInAppSettings()(getState());
    const isSAPHybrisActivatedInSettings =
      makeSelectIsSAPHybrisActivatedInAppSettings()(getState());
    const isMotoristApp = makeSelectIsMotoristApp()(getState());
    const isFireblade = makeSelectIsMotoristFirebladeCVP()(getState());
    const { redirect, service } = makeSelectQuery()(getState());
    const isGoPlusCVP = makeSelectIsGoPlusCVP()(getState());
    const isPartnerCVP = makeSelectIsPartnerCVP()(getState());
    const isLoyaltyFuturesCVP = makeSelectIsLoyaltyFuturesCVP()(getState());
    const isSAPHybrisCVP = makeSelectIsSAPHybrisCVP()(getState());

    const loyaltyActivatedOnProfile = profile?.loyalty?.activated || false;
    const goPlusActivatedOnProfile = profile?.goplus?.activated || false;

    const isLoyaltyFlow =
      isLoyaltyActivatedInSettings &&
      (service === LOYALTY_SERVICE || isFireblade) &&
      !loyaltyActivatedOnProfile; // Coming from SOL Web or from Fireblade app
    const isLoyaltyFuturesFlow =
      isLoyaltyFuturesActivatedInSettings &&
      isLoyaltyFuturesCVP &&
      !goPlusActivatedOnProfile;
    const isSAPHybrisFlow =
      isSAPHybrisActivatedInSettings &&
      isSAPHybrisCVP &&
      !goPlusActivatedOnProfile;
    const isGoPlusWebFlow =
      isGoPlusActivatedInSettings && isGoPlusCVP && !isMotoristApp;

    const showNoLoyaltyTermsScreen = await dispatch(
      noLoyaltyMarket(
        profile,
        isLoyaltyFuturesActivatedInSettings,
        isLoyaltyFuturesCVP
      )
    );

    if (showNoLoyaltyTermsScreen) {
      return;
    }

    await dispatch(loginSuccessHandlePartnerLogic(profile));
    dispatch(sendLoginSuccessAnalytics(loginFlow, mfaFlow, profile));

    let ignoreSpinner = false;

    if (
      checkSaveProfile({
        isGoPlusWebFlow,
        isPartnerCVP,
        isLoyaltyFlow,
        isLoyaltyFuturesFlow,
        isSAPHybrisFlow,
      })
    ) {
      dispatch(loadUserProfile({ ...profile }));
    }

    if (isGoPlusWebFlow || isPartnerCVP) {
      dispatch(goPlusRedirect());
      ignoreSpinner = true;
    } else if (isLoyaltyFlow) {
      dispatch(startSolFlow(translate));
    } else if (isLoyaltyFuturesFlow) {
      dispatch(startLoyaltyFuturesFlow(translate));
    } else if (isSAPHybrisFlow) {
      dispatch(startSAPHybrisFlow(translate));
    } else if (isMotoristApp || redirect) {
      dispatch(startOtherFlow(url));
      ignoreSpinner = true;
    } else {
      browserHistory.push(PROFILE_PATHNAME);
    }
    if (!ignoreSpinner) dispatch(setSpinner(false));
  };
}

export function checkSaveProfile(params) {
  for (const key in params) {
    if (Object.hasOwn(params, key)) {
      const element = params[key];
      if (element) {
        return true;
      }
    }
  }
  return false;
}

export function loginSuccessHandlePartnerLogic(profile) {
  return async (dispatch, getState) => {
    const isPartnerActivated = makeSelectIsPartner()(getState());
    const settingsPartnerByClientId = makeSelectSettingsPartnersByClientId()(
      getState()
    );
    const profilePartners = profile.externalSystemIds;

    const checkedPartnerData = checkPartnerOnExternalSystemIds(
      settingsPartnerByClientId,
      profilePartners
    );

    if (isPartnerActivated) {
      if (
        !settingsPartnerByClientId &&
        !makeSelectIsNotificationVisible()(getState())
      ) {
        dispatch(
          showDefaultErrorNotification(
            () => dispatch(redirectToCVP()),
            lokaliseTags.SSO_TECHNICAL_ERROR_ARCHETYPE
          )
        );
        return;
      }

      if (settingsPartnerByClientId && !checkedPartnerData) {
        const externalSystemIds = {
          identifier: settingsPartnerByClientId.partner_code,
          name: settingsPartnerByClientId.partner_name,
        };
        try {
          dispatch(setSpinner(true));
          await saveProfile(
            {
              profile: {
                firstName: profile.profile.firstName,
                lastName: profile.profile.lastName,
              },
              externalSystemIds,
            },
            { noSpinner: true }
          );
        } catch {
          dispatch(showDefaultErrorNotification());
          return;
        }
      }
    }
  };
}

export const userBlocked = () => {
  return async (dispatch, getState) => {
    const isApp = makeSelectIsMotoristApp()(getState());
    dispatch(
      showNotification(
        buildNotification({
          animation: LockAnimation,
          title: lokaliseTags.SSO_SIGNIN_BLOCKED_SCREEN_TITLE,
          text: lokaliseTags.SSO_SIGNIN_BLOCKED_SCREEN_BODY,

          buttons: [
            {
              label: isApp
                ? lokaliseTags.SSO_SIGNIN_BLOCKED_SCREEN_MOBILE_BUTTON
                : lokaliseTags.SSO_SIGNIN_BLOCKED_SCREEN_DESKTOP_BUTTON,
              event: () => {
                const helpUrl = makeSelectSettingsHelpURL()(getState());
                return isApp
                  ? dispatch(redirectToCVP({ url: OPEN_HELP_MFA_APP }))
                  : window.open(
                      helpUrl ?? 'https://support.shell.com',
                      '_blank'
                    );
              },
            },
          ],
        })
      )
    );
  };
};

export const userBlockedForever = () => {
  return async (dispatch, getState) => {
    dispatch(
      sendAdobeAnalyticsEvent(adobeAnalyticTags.userBlockedForever.pageLoad)
    );
    const buttonStyles = {
      margin: '0 4px',
      padding: '0',
      maxWidth: '370px',
      minWidth: '190px',
    };
    dispatch(
      showNotification(
        buildNotification({
          animation: LockAnimation,
          title: lokaliseTags.BLOCKED_FOREVER_ERROR_LABEL,
          text: lokaliseTags.BLOCKED_FOREVER_ERROR_MESSAGE,
          buttonsWrapper: {
            display: 'flex',
            justifyContent: 'center',
          },
          buttons: [
            {
              label: lokaliseTags.BLOCKED_FOREVER_ERROR_BUTTON_SECONDARY,
              event: () => {
                dispatch(
                  sendAdobeAnalyticsEvent(
                    adobeAnalyticTags.userBlockedForever.clickButton,
                    'tileClick'
                  )
                );
                return dispatch(redirectToCVP({ showSpinner: true }));
              },
              variant: 'outlined',
              keepOpen: false,
              btnSpecialStyles: {
                ...buttonStyles,
              },
            },
            {
              label: lokaliseTags.BLOCKED_FOREVER_ERROR_BUTTON_PRIMARY,
              event: () => {
                dispatch(
                  sendAdobeAnalyticsEvent(
                    adobeAnalyticTags.userBlockedForever.clickButton,
                    'tileClick'
                  )
                );
                const helpUrl = makeSelectSettingsHelpURL()(getState());
                return window.open(
                  helpUrl ?? 'https://support.shell.com',
                  '_blank'
                );
              },
              btnSpecialStyles: {
                ...buttonStyles,
              },
            },
          ],
        })
      )
    );
  };
};

export function sendLoginSuccessAnalytics(
  loginFlow,
  mfaFlow = false,
  profile = null
) {
  return async (dispatch, getState) => {
    const isMailAuthentication = makeSelectEmailAuthentication()(getState());

    const payload = {
      status: 'Logged in',
      authenticationMethod: isMailAuthentication ? 'email' : 'phone',
      signinOrigin: loginFlow ? 'manual' : 'automatic',
      mfa: mfaFlow ? 'otp' : 'no',
    };
    if (profile) {
      if (mfaFlow && isMailAuthentication) {
        payload.fakeId = generateHash(profile?.profile?.emailAddress);
      } else if (!isMailAuthentication) {
        const mobile = profile?.profile?.phone?.mobile
          ? cloneDeep(profile.profile.phone.mobile).replaceAll(' ', '')
          : undefined;
        payload.fakeId = generateHash(mobile);
      }
    }
    const trackClick = 'sso_signin_success';
    dispatch(sendAdobeAnalyticsEvent(payload, trackClick));
  };
}

function handleSolLoginErrors(status, data) {
  return async (dispatch, getState) => {
    const formPage = makeSelectFormPageName()(getState());
    const market = makeSelectQueryMarket()(getState());
    const error = {};
    if (status === CARD_ALREADY_MIGRATED_TO_SSO) {
      if (formPage === LOGIN_PAGE) {
        error.currentPassword =
          lokaliseTags.SSO_FORMS_ERRORS_USERNAME_PASSWORD_INVALID;
      } else if (formPage === SOL_LOGIN_PAGE) {
        error.solPassword = lokaliseTags.SSO_FORMS_ERRORS_CARD_ALREADY_MIGRATED;
      } else if (formPage === SOL_MIGRATION_PAGE) {
        dispatch(showCardMigratedPopup(data.username));
      } else {
        error.solPassword =
          lokaliseTags.SSO_FORMS_ERRORS_CARD_ALREADY_MIGRATED_SOL;
      }
    } else if (status === DISALLOWED_CARD_TYPE) {
      dispatch(showNotSupportedNotification());
    } else if (status === CARD_BLOCKED || status === CARD_DISABLED) {
      dispatch(showLoyaltyCardBlockedNotification());
    } else if (status === INVALID_CARD_ID_LENGTH) {
      if (formPage === LOGIN_PAGE) {
        error.signInEmailAddress = isNaN(data.username)
          ? lokaliseTags.SSO_FORMS_ERRORS_NO_ACCOUNTS_WITH_THAT_EMAIL_ADDRESS_EXISTS
          : lokaliseTags.SSO_FORMS_ERRORS_CARD_NOT_VALID;
      } else {
        error.username = isNaN(data.username)
          ? lokaliseTags.SSO_FORMS_ERRORS_NO_ACCOUNTS_WITH_THAT_EMAIL_ADDRESS_EXISTS
          : lokaliseTags.SSO_FORMS_ERRORS_CARD_NOT_VALID;
      }
    } else if (status === CARD_NOT_FOUND) {
      error.signInEmailAddress = lokaliseTags.SSO_FORMS_ERRORS_CARD_NOT_VALID;
      error.cardId = lokaliseTags.SSO_FORMS_ERRORS_CARD_NOT_VALID;
    } else if (
      formPage === LOYALTY_MIGRATION_PAGE ||
      formPage === SOL_LOGIN_PAGE
    ) {
      error.solPassword =
        lokaliseTags.SSO_FORMS_ERRORS_USERNAME_PASSWORD_INVALID;
    } else if (formPage === SOL_MIGRATION_PAGE) {
      error.solPassword =
        lokaliseTags.SSO_FORMS_ERRORS_INCORRECT_USERNAME_OR_PASSWORD_PLEASE_TRY_AGAIN;
      dispatch(
        sendAdobeAnalyticsEvent(
          {
            ...adobeAnalyticTags[SOL_MIGRATION_PAGE].pageLoad,
            errorMessage: error.solPassword,
          },
          'error'
        )
      );
    } else {
      const isMotoristApp = makeSelectIsMotoristApp()(getState());
      error.currentPassword =
        lokaliseTags.SSO_FORMS_ERRORS_USERNAME_PASSWORD_INVALID;
      if (getMarketCountry(market) === UK_MARKET && isMotoristApp) {
        try {
          const pageName = makeSelectFormPageName()(getState());
          const recaptchaToken = await getCaptchaToken(pageName);
          await checkEmailExist({
            email:
              getState().dynamicForm.formData.signInEmailAddress.toLowerCase(),
            recaptchaToken,
          });
        } catch (err) {
          error.signInEmailAddress =
            lokaliseTags.SSO_FORMS_ERRORS_EMAIL_UNKNOWN;
          error.currentPassword = '';
        }
      }
    }
    dispatch(setFormError(error));
    const step = 0;
    dispatch(setFormStep(step));
  };
}

function startSolFlow(translate) {
  return () => {
    browserHistory.push(SOL_ATTACH_WELCOME_PATHNAME);
    document.title = translate(browserTabNameLogged);
  };
}

function startSAPHybrisFlow(translate) {
  return () => {
    browserHistory.push(SAP_HYBRIS_ATTACH_WELCOME_PATHNAME);
    document.title = translate(browserTabNameLogged);
  };
}

function startLoyaltyFuturesFlow(translate) {
  return async (dispatch) => {
    try {
      await lodAttach({});
      browserHistory.push(LOYALTY_FUTURES_ATTACH_WELCOME_PATHNAME);
      document.title = translate(browserTabNameLogged);
    } catch (error) {
      dispatch(
        showDefaultErrorNotification(
          null,
          lokaliseTags.SSO_ERROR_GENERIC_JANRAIN
        )
      );
      dispatch(getLoginForm());
    }
  };
}

function startOtherFlow(url) {
  return (dispatch) => {
    const redirectData = {
      url,
      showSpinner: true,
    };
    dispatch(redirectToCVP(redirectData));
  };
}

export default {
  initializeLogin,
  loginWithSOL,
  loginWithSSO,
  getLoginForm,
};
