import merge from 'lodash/fp/merge';
import lokaliseTags from '../../shared/lokaliseTags';

export function buildNotification({
  animation,
  icon = false,
  title,
  text,
  textModifier = [],
  subText,
  buttons = [],
  closeAction,
  closeIcon = 'close',
  snackbar = { message: null },
  QRCode,
  walletButton = false,
  automatedRedirect,
  appStoreAnalyticClickFn = false,
  appStoreButtons,
  contentWrapper,
  buttonsWrapper,
  link = null,
  linkEvent,
}) {
  const notification = {
    animation: {
      component: animation,
    },
    icon,
    message: {
      title,
      text,
      subText,
      textModifier,
      link,
      linkEvent,
    },
    QRCode,
    automatedRedirect,
    appStoreAnalyticClickFn,
    appStoreButtons,
    walletButton,
    contentWrapper,
    buttonsWrapper,
    snackbar,
  };

  if (closeAction) {
    notification.header = {
      icon: {
        name: lokaliseTags.SSO_GENERAL_CLOSE,
        icon: closeIcon,
        event: typeof closeAction === 'function' ? closeAction : () => {},
        desktop: true,
        mobile: true,
      },
    };
  }

  return merge(notification, { buttons });
}

export const ANDROID_APP = 'ANDROID';
export const IOS_APP = 'IOS';
