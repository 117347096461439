import React from 'react';
import get from 'lodash/get';
import browserHistory from '../../../router/history';
import { LOYALTY_INITIAL_PAGE } from '../../../shared/formPages';
import { getLoyaltyInitialForm } from '@shell-b2c/http-frontend/dist/src/components/core/forms';
import {
  cardStatus,
  cardLink,
} from '@shell-b2c/http-frontend/dist/src/components/core/loyalty';
import {
  INVALID_CARD_ID,
  CARD_NOT_FOUND,
  CARD_BLOCKED,
  CARD_DISABLED,
  CARD_ALREADY_MIGRATED_TO_SSO,
  CARD_NON_PERSONALISED_BUT_NOT_REGISTERED,
  CARD_PERSONALIZED_BUT_NOT_REGISTERED,
  SOL_REGISTRATION_BELONGS_TO_THIS_CARD_NUMBER,
  DISALLOWED_CARD_TYPE,
  CARD_ADAC_PERSONALIZED_NOT_REGISTERED,
  CARD_PAYONSITE_NOT_PAID,
  ADAC_MEMBERSHIP_NOT_VALID,
} from '../../../shared/solResponseErrorCodes';
import {
  PERSONALIZED,
  NON_PERSONALISED,
} from '../../../shared/loyaltyCardTypes';
import {
  NEW_LOYALTY_PATHNAME,
  LOGIN_PATHNAME,
  LOYALTY_PATHNAME,
  PROFILE_PATHNAME,
  SOL_ATTACH_PAYSONSITE_DETAILS_PATHNAME,
  SOL_ATTACH_PAYSONSITE_ERROR_PATHNAME,
  SOL_PASSWORD_PATHNAME,
} from '../../../shared/pathnames';
import adobeAnalyticTags from '../../../shared/adobeAnalyticTags';
import { makeSelectLoyaltyChangeCardUrl } from '../../settings/selectors';
import {
  showDefaultErrorNotification,
  showNotification,
} from '../../notification/actions';
import {
  setForm,
  validateForm,
  setFormStep,
  setFormError,
  setFormData,
} from '../../dynamic-form/actions';
import { removeFormPropertyInOrder } from '../../dynamic-form/utils';
import {
  VALIDATION_ERROR,
  UPDATE_ATTRIBUTE,
} from '../../dynamic-form/constants';
import ProfileActions from '../../profile/actions';
import { makeSelectLodCardsByType } from '../../profile/selectors';
import {
  sendAdobeAnalyticsEvent,
  redirectToCVP,
  setSpinner,
  setupLoyaltyNotification,
} from '../../app/actions';
import { showPopup, closePopup } from '../../popup/actions';
import { buildPopup } from '../../popup/utils';
import { OFFLINE_NOTIFICATION } from '../../app/notifications';
import { initializeHeader, saveLoyalty } from '../shared/actions';
import { CHANGE_VALUE } from '../shared/constants';
import ConeAnimation from '../../../shared/components/ConeAnimation';
import { buildNotification } from '../../notification/utils';
import { CARD_TYPES_TREATMENT } from './constants';
import { LOYALTY_SERVICE } from '../../../shared/formServices';
import {
  makeSelectAccessToken,
  makeSelectCVP,
  makeSelectIsMotoristApp,
  makeSelectQuery,
  makeSelectQueryMarket,
} from '../../app/selectors';
import { makeSelectFormData } from '../../dynamic-form/selectors';
import { createUserProfileFromLoyaltyInitialFormData } from '../../../shared/mapping';
import { accessCode } from '@shell-b2c/http-frontend/dist/src/components/core/auth';
import { redirectWithData } from '../../../shared/utils';
import { Icon } from '@shell-b2c/web-design-system';
import lokaliseTags from '../../../shared/lokaliseTags';
import { getProfile } from '@shell-b2c/http-frontend/dist/src/components/core/user';
import { isFunction } from 'lodash';

/**
 * CARD INPUT
 */
export function initializeCardInput(setHeader) {
  return async (dispatch, getState) => {
    if (!getState().dynamicForm.form.loyaltyInitial) {
      try {
        const userProfile = await getProfile();
        const initialForm = await getLoyaltyInitialForm();
        let form = { ...initialForm };

        if (get(userProfile, 'consents.loyaltyOptIn')) {
          form = removeFormPropertyInOrder(form, 'acceptLoyaltyTerms');
        }

        dispatch({
          type: CHANGE_VALUE,
          name: LOYALTY_INITIAL_PAGE,
          value: form,
        });
        const data = {
          form,
          errors: {},
          user: { cardId: '' },
        };
        const loyaltyForm = {
          name: LOYALTY_INITIAL_PAGE,
          data,
        };
        dispatch(setForm(loyaltyForm));
        dispatch(setFormData({}));
        dispatch(
          ProfileActions.handleUserProfile((_) =>
            dispatch(initializeHeader(setHeader))
          )
        );

        dispatch(
          sendAdobeAnalyticsEvent(
            adobeAnalyticTags[LOYALTY_INITIAL_PAGE].pageLoad
          )
        );
      } catch (error) {
        dispatch(showDefaultErrorNotification());
      }
    } else {
      const data = {
        form: getState().loyalty.loyaltyInitial,
        errors: {},
        user: { cardId: '' },
      };
      const loyaltyForm = {
        name: LOYALTY_INITIAL_PAGE,
        data,
      };
      dispatch(setForm(loyaltyForm));
      dispatch(
        ProfileActions.handleUserProfile(
          (_) => dispatch(initializeHeader(setHeader)),
          setHeader
        )
      );
    }
  };
}

export function handleCardInputSubmit() {
  return async (dispatch, getState) => {
    if (!navigator.onLine) {
      dispatch(showNotification(OFFLINE_NOTIFICATION));
      return;
    }

    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags[LOYALTY_INITIAL_PAGE].continueButton,
        'tileClick'
      )
    );

    try {
      await dispatch(validateForm(LOYALTY_INITIAL_PAGE));
      const step = 0;
      dispatch(setFormStep(step));
      const data = {
        market: getState().app.query.market,
        cardId: getState().dynamicForm.formData.cardId,
      };
      await cardLink();
      dispatch(validateCardStatus(data));
    } catch (error) {
      if (error && isFunction(error.badVerify) && error.badVerify()) {
        const data = {
          market: getState().app.query.market,
          cardId: getState().dynamicForm.formData.cardId,
        };
        return dispatch(validateCardStatus(data));
      } else if (error.type === VALIDATION_ERROR) {
        dispatch({
          type: CHANGE_VALUE,
          name: 'showCSC',
          value: getState().loyalty.showCSC + 1,
        });
      } else if (
        isFunction(error?.linkStatusNotAllowed) &&
        error?.linkStatusNotAllowed()
      ) {
        dispatch(
          sendAdobeAnalyticsEvent(
            adobeAnalyticTags[LOYALTY_INITIAL_PAGE].errorInputCardLink,
            'error'
          )
        );
        dispatch(
          setFormError({
            cardId: lokaliseTags.SSO_FORMS_ERRORS_CARDID_CANNOT_ADD_EXTRA_CARD,
          })
        );
      } else {
        dispatch(
          showDefaultErrorNotification(null, lokaliseTags.SSO_GENERIC_SOL)
        );
      }
    }
  };
}

export function showCardPopup() {
  return (dispatch) => {
    const popup = buildPopup({
      text: lokaliseTags.SSO_ATTACH_FIND_CARD_FRONT_OR_BACK_DIALOGUE,
      image: (
        <Icon type="cardinfo-number" style={{ width: 380, height: 285 }} />
      ),
      buttons: [
        {
          label: lokaliseTags.SSO_GENERAL_NEXT,
          event: () => {
            dispatch(
              sendAdobeAnalyticsEvent(
                adobeAnalyticTags.solCardDialogInfo.cta,
                'tileClick'
              )
            );
            dispatch(closePopup());
          },
        },
      ],
    });

    dispatch(
      sendAdobeAnalyticsEvent(adobeAnalyticTags.solCardDialogInfo.pageLoad)
    );

    dispatch(showPopup(popup));
  };
}

export function saveCardType(cardType) {
  return {
    type: UPDATE_ATTRIBUTE,
    name: 'cardType',
    value: cardType,
  };
}

export function validateCardStatus(data) {
  return async (dispatch, getState) => {
    const {
      card_type: response_card_type,
      response_status: status,
      pay_on_site,
    } = await cardStatus(data);

    dispatch(saveCardType(response_card_type));

    function dispatchError(errorTag) {
      dispatch({
        type: CHANGE_VALUE,
        name: 'showCSC',
        value: getState().loyalty.showCSC + 1,
      });
      dispatch(setFormError({ cardId: errorTag }));
    }

    function dispatchRedirect(path) {
      dispatch({
        type: CHANGE_VALUE,
        name: 'showCSC',
        value: 0,
      });
      browserHistory.push(path);
    }

    const beforeMergePopupActions = {
      // Form errors
      [INVALID_CARD_ID]: () =>
        dispatchError(lokaliseTags.SSO_FORMS_ERRORS_INVALID_CARD),
      [CARD_NOT_FOUND]: () =>
        dispatchError(lokaliseTags.SSO_FORMS_ERRORS_INVALID_CARD),
      [CARD_BLOCKED]: () =>
        dispatchError(lokaliseTags.SSO_FORMS_ERRORS_BLOCKED_CARD),
      [CARD_DISABLED]: () =>
        dispatchError(lokaliseTags.SSO_FORMS_ERRORS_DISABLED_CARD),

      // Alerts
      [DISALLOWED_CARD_TYPE]: () => dispatch(showNotSupportedNotification()),
      [CARD_ALREADY_MIGRATED_TO_SSO]: () =>
        dispatch(showCardMigratedPopup(data.cardId)),
      [ADAC_MEMBERSHIP_NOT_VALID]: () =>
        dispatch(showADACMembershipNotValidNotification()),
      // Redirections
      [CARD_PAYONSITE_NOT_PAID]: () =>
        dispatchRedirect(SOL_ATTACH_PAYSONSITE_ERROR_PATHNAME),
    };

    const afterMergePopupActions = {
      // Alerts
      [CARD_ADAC_PERSONALIZED_NOT_REGISTERED]: () =>
        dispatch(
          showNotSupportedNotification(
            lokaliseTags.SSO_ADAC_CARD_NOT_SUPPORTED_TEXT
          )
        ),

      // Redirections
      [CARD_PERSONALIZED_BUT_NOT_REGISTERED]: () =>
        dispatchRedirect(`${NEW_LOYALTY_PATHNAME}/${PERSONALIZED}`),
      [CARD_NON_PERSONALISED_BUT_NOT_REGISTERED]: () => {
        if (pay_on_site) {
          return dispatch(showSmartDealPopup());
        }

        dispatchRedirect(`${NEW_LOYALTY_PATHNAME}/${NON_PERSONALISED}`);
      },
      [SOL_REGISTRATION_BELONGS_TO_THIS_CARD_NUMBER]: () =>
        dispatchRedirect(SOL_PASSWORD_PATHNAME),
    };

    const dispatchAction =
      afterMergePopupActions[status] ||
      (() => {
        // Do nothing
      });

    const cardType =
      CARD_TYPES_TREATMENT[response_card_type] || response_card_type;
    // Check if user has already a card of the same type
    const cardsWithSameType = makeSelectLodCardsByType(cardType)(getState());

    if (beforeMergePopupActions[status]) {
      return beforeMergePopupActions[status]();
    }

    if (cardsWithSameType.length && status !== CARD_ALREADY_MIGRATED_TO_SSO) {
      return dispatch(showMergeCardsPopup(dispatchAction));
    }

    dispatchAction();
  };
}

export function showNotSupportedNotification(
  text = lokaliseTags.SSO_ERROR_CARD_NOT_SUPPORTED_TEXT
) {
  return (dispatch) => {
    const notification = buildNotification({
      animation: ConeAnimation,
      title: lokaliseTags.SSO_CARD_NOT_SUPPORTED_TITLE,
      text,
      buttons: [
        {
          label: lokaliseTags.SSO_GENERAL_GO_BACK,
          event: () =>
            dispatch(
              sendAdobeAnalyticsEvent(
                adobeAnalyticTags.solCardNotSupported.cta,
                'tileClick'
              )
            ),
        },
      ],
    });

    dispatch(
      sendAdobeAnalyticsEvent(adobeAnalyticTags.solCardNotSupported.pageLoad)
    );

    dispatch(showNotification(notification));
  };
}

export function showADACMembershipNotValidNotification() {
  return (dispatch) => {
    const notification = buildNotification({
      animation: ConeAnimation,
      title: lokaliseTags.SSO_ADAC_UNVERIFIED_MEMBERSHIP_TITLE,
      text: lokaliseTags.SSO_ADAC_UNVERIFIED_MEMBERSHIP_TEXT,
      buttons: [
        {
          label: lokaliseTags.SSO_GENERAL_GO_BACK,
          event: () =>
            dispatch(
              sendAdobeAnalyticsEvent(
                adobeAnalyticTags.solADACMembershipNotPaid.cta,
                'tileClick'
              )
            ),
        },
      ],
    });

    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags.solADACMembershipNotPaid.pageLoad
      )
    );

    dispatch(showNotification(notification));
  };
}

export function showCardMigratedPopup(cardId) {
  return (dispatch) => {
    const popup = buildPopup({
      text: {
        key: lokaliseTags.SSO_MIGRATION_PROMPT_CARD_EXISTS,
        values: [cardId],
      },
      buttons: [
        {
          label: lokaliseTags.SSO_GENERAL_CANCEL_BUTTON,
          event: () => {
            dispatch(
              sendAdobeAnalyticsEvent(
                adobeAnalyticTags.solCardMigrated.cancelButton,
                'tileClick'
              )
            );
            dispatch(closePopup());
          },
          outlined: true,
        },
        {
          label: lokaliseTags.SSO_MIGRATION_CONFIRM_EMAIL_PROMPT_SIGN_IN,
          event: () => {
            dispatch(
              sendAdobeAnalyticsEvent(
                adobeAnalyticTags.solCardMigrated.signInButton,
                'tileClick'
              )
            );
            browserHistory.push(LOGIN_PATHNAME);
          },
        },
      ],
    });

    dispatch(
      sendAdobeAnalyticsEvent(adobeAnalyticTags.solCardMigrated.pageLoad)
    );

    dispatch(showPopup(popup));
  };
}

export function showSmartDealPopup() {
  return (dispatch) => {
    const popup = buildPopup({
      title: lokaliseTags.SSO_PAYONSITE_COMPLETE_SUBSCRIPTION_TITLE,
      text: lokaliseTags.SSO_PAYONSITE_COMPLETE_SUBSCRIPTION_TEXT,
      buttons: [
        {
          label: lokaliseTags.SSO_GENERAL_CANCEL_BUTTON,
          event: () => {
            dispatch(
              sendAdobeAnalyticsEvent(
                adobeAnalyticTags.solSmartDealDialog.cancelButton,
                'tileClick'
              )
            );
            dispatch(closePopup());
          },
          outlined: true,
        },
        {
          label: lokaliseTags.SSO_ATTACH_CONTINUE_BUTTON,
          event: () => {
            dispatch(
              sendAdobeAnalyticsEvent(
                adobeAnalyticTags.solSmartDealDialog.continueButton,
                'tileClick'
              )
            );
            browserHistory.push(SOL_ATTACH_PAYSONSITE_DETAILS_PATHNAME);
          },
        },
      ],
    });

    dispatch(
      sendAdobeAnalyticsEvent(adobeAnalyticTags.solSmartDealDialog.pageLoad)
    );

    dispatch(showPopup(popup));
  };
}

export function showMergeCardsPopup(callback) {
  return (dispatch) => {
    const popup = buildPopup({
      title: lokaliseTags.SSO_MERGE_DIALOG_TITLE,
      text: lokaliseTags.SSO_MERGE_DIALOG_TEXT,
      buttons: [
        {
          label: lokaliseTags.SSO_GENERAL_CANCEL_BUTTON,
          event: () => {
            dispatch(
              sendAdobeAnalyticsEvent(
                adobeAnalyticTags.solMergeCards.cancelButton,
                'tileClick'
              )
            );
          },
          outlined: true,
        },
        {
          label: lokaliseTags.SSO_REPLACE_CARDS_BUTTON,
          event: () => {
            dispatch(
              sendAdobeAnalyticsEvent(
                adobeAnalyticTags.solMergeCards.signInButton,
                'tileClick'
              )
            );
            callback();
          },
        },
      ],
    });

    dispatch(sendAdobeAnalyticsEvent(adobeAnalyticTags.solMergeCards.pageLoad));

    dispatch(showPopup(popup));
  };
}

export function quitLoyalty() {
  return (dispatch, getState) => {
    const isMotoristApp = makeSelectIsMotoristApp()(getState());
    if (isMotoristApp) {
      dispatch(redirectToCVP());
    } else if (
      getState().app.query.service === LOYALTY_SERVICE &&
      getState().profile.loyalty.activated !== true
    ) {
      browserHistory.push(LOYALTY_PATHNAME);
    } else {
      browserHistory.push(PROFILE_PATHNAME);
    }
  };
}

export function generateLoyaltyData() {
  return (_, getState) => {
    const formData = makeSelectFormData()(getState());
    const market = makeSelectQueryMarket()(getState());
    const cvp = makeSelectCVP()(getState());

    return createUserProfileFromLoyaltyInitialFormData(
      { ...formData, migrationOption: 'FRESH' },
      market,
      cvp
    );
  };
}

export function attachDigitalCard({
  attachFromData = false,
  options = {},
} = {}) {
  return (dispatch) => {
    const loyaltyData = attachFromData ? dispatch(generateLoyaltyData()) : {};
    dispatch(setupLoyaltyNotification());
    return dispatch(saveLoyalty({ ...loyaltyData, options }));
  };
}

/**
 * SMART DEAL
 */

export function initializeSmartdeal() {
  return async (dispatch, getState) => {
    const { cardId } = makeSelectQuery()(getState());

    dispatch(setSpinner(true));

    const redirectUrl = await dispatch(generateSmartdealRedirectURL());

    if (redirectUrl) {
      return redirectWithData(redirectUrl, {
        smartdealCardId: cardId,
      });
    }
  };
}

function generateSmartdealRedirectURL() {
  return async (_, getState) => {
    const accessToken = makeSelectAccessToken()(getState());
    const response = await accessCode({ accessToken });

    const cardChangeCardUrl = makeSelectLoyaltyChangeCardUrl(
      response.accessCode
    )(getState());
    return cardChangeCardUrl.replace(
      'action=manage_cards&',
      'action=smartdeal&'
    );
  };
}

export default {
  showCardPopup,
};
