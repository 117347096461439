import { RED, MID_GREY, VERY_DARK_GREY, WHITE } from '../../shared/colors';
import { points10, points12, points14 } from '../../shared/fontSizes';

export default {
  button: {
    margin: '0 auto',
    maxWidth: '370px',
    padding: '0 16px',
    display: 'flex',
    justifyContent: 'center',
  },
  Links: {
    margin: '0 auto 20px',
    width: '100%',
    backgroundColor: WHITE,
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    backgroundColor: WHITE,
    display: 'flex',
    alignItems: 'center',
    left: '0',
    top: '0',
    overflow: 'hidden',
    width: '100%',
    margin: '0',
    padding: '0',
    zIndex: '10000000',
    height: '100%',
  },
  centered: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    alignItems: 'center',
  },
  toTop: {
    position: 'fixed',
    zIndex: '10000000',
    width: '100%',
    top: 0,
    left: 0,
    cursor: 'pointer',
  },
  headerIconWrapper: {
    padding: '19px 15px',
    display: 'block',
    overflow: 'hidden',
    backgroundColor: WHITE,
  },
  headerIconWrapperDesktop: {
    padding: '19px 20px',
    position: 'absolute',
    right: 0,
    textAlign: 'right',
  },
  headerTitleWrapper: {
    marginTop: '10px',
    textAlign: 'center',
  },
  headerTitle: {
    fontSize: '16px',
    color: RED,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
  },
  headerSubTitle: {
    color: VERY_DARK_GREY,
    fontSize: points12,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
  },
  notificationImage: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    margin: '0px 0px 30px',
    minHeight: '100px',
  },
  notificationSlider: {},
  fade: {
    backgroundColor: WHITE,
    position: 'fixed',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    zIndex: '9000',
  },
  contentWrapper: {
    top: '0',
    margin: '0',
    padding: '0',
    width: '100%',
    overflow: 'none',
    marginBottom: '40px',
  },
  buttonWrapper: {
    width: '100%',
    bottom: 0,
    position: 'absolute',
  },
  contentStyle: {
    padding: '0 15px',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  fullWidth: {
    padding: '0',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  imgTitle: {
    textAlign: 'left',
    fontSize: points14,
    fontWeight: 'bold',
    paddingLeft: '15px',
  },
  imgText: {
    textAlign: 'left',
    fontSize: points12,
    paddingLeft: '15px',
  },
  sliderTitle: {
    fontSize: points12,
    color: MID_GREY,
    borderBottom: `1px solid ${MID_GREY}`,
    textTransform: 'uppercase',
    paddingBottom: '5px',
    margin: '0',
  },
  sliderText: {
    textAlign: 'left',
    fontSize: points12,
    borderBottom: `1px solid ${MID_GREY}`,
    paddingBottom: '5px',
  },
  toggle: {
    float: 'right',
    margin: '42px 20px',
  },
  imgTextBottom: {
    position: 'absolute',
    left: '0',
    width: '100%',
  },
  imageStyle: {
    width: '100px',
    height: '100px',
  },
  noAddedSpace: {
    margin: '0',
    padding: '0',
  },
  titleText: {
    textAlign: 'center',
    color: RED,
    paddingBottom: '10px',
  },
  messageText: {
    whiteSpace: 'pre-line',
    marginBottom: '0',
    marginTop: '16px',
    textAlign: 'center',
  },
  subtitleText: {
    whiteSpace: 'pre-line',
    marginBottom: '0',
    fontSize: points10,
    marginTop: '80px',
    textAlign: 'center',
  },
  cursorPointer: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: VERY_DARK_GREY,
  },
  checkboxes: {
    borderTop: `1px solid ${MID_GREY}`,
  },
  twoButtonsStyle: {
    width: '300px',
    minHeight: '40px',
    border: `1px solid ${VERY_DARK_GREY}`,
    backgroundColor: WHITE,
    borderRadius: '4px',
    textAlign: 'center',
    lineHeight: '40px',
    padding: '0 8px',
  },
  QRContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '32px',
  },
  QRCode: {
    width: '5rem',
  },
  walletButtons: {
    width: '8.1rem',
    height: '2.75rem',
  },
  appStoreButton: {
    width: '8.1rem',
    height: '2.75rem',
  },
  appStoreButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
  },
};
